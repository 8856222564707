.styled-radioBtn:checked+label:before {
    background: #e9d2a4;
}
.styled-radioBtn+label:before {
    content: "";
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 19px;
    height: 19px;
    border: 1px solid #e4cea2;
    border-radius: 50%;
    background-color: #e9d2a4;
}
.styled-radioBtn:checked+label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 5px;
    width: 6px;
    height: 11px;
    border: solid #931217;
    border-width: 0px 3px 3px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.styled-radioBtn.styled-radio+label:before {
    margin-right: 15px;
}


.setting-styled-radioBtn+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background-color: #fff;
}
.credit_card .setting-styled-radioBtn+label:after {
    top: 6px !important;
}
.credit_card .setting-styled-radioBtn+label:before {
    vertical-align: baseline;
}
.setting-styled-radioBtn:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.setting-styled-radioBtn:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.setting-styled-radioBtn+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #931217;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.member_ship_add_on .setting-styled-radioBtn+label:after {
    top: 5px;
}
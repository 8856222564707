@keyframes dropdown-animation {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}
.text-ellipse {
  text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 125px;
    overflow: hidden;
}
@media (max-width:991px) {
  .text-ellipse {
    max-width: 80px;
  }
}

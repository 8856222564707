@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Quicksand-Regular';
  font-size: 20px;
  color: #262626;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f6ecc8;
}
.container {
  display: grid;
  grid-template-columns: 33% 67%;
  grid-template-rows: auto auto;
}

.image {
  grid-column-start: 1;
}

.featured_data {
  grid-column-start: 1;
  grid-row-start: 2;
}

.profile_data {
  grid-column-start: 2;
  grid-row: span 2;
}
img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 100%;
}
.object-position-top-center {
  object-position: top center;
}

::placeholder {
  color: #262626 !important;
  font-family: 'Quicksand-Regular';
  font-size: 18px;
  -webkit-text-stroke: 0.3px;
}
.width-full-webkit{
  width: 50px;
}
.rc-time-picker-clear {
  top: 6px !important;
}
.date_picker_wrapper .rc-time-picker-input {
  padding-left: 16px;
  padding-right: 16px;
}
.flatpickr-day.selected {
  background: #ed1c24 !important;
  border-color: #ed1c24 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,span.flatpickr-weekday,.flatpickr-weekdays,.flatpickr-months .flatpickr-month,.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background: #ed1c24 !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #ed1c24 !important;
}
.rc-time-picker-input {
  border: none !important;
  height: -webkit-fill-available !important;
  line-height: normal !important;
  display: block !important;
  border-radius: 8px !important;
  color: #262626 !important;
  font-family: 'Quicksand-Regular' !important;
  font-size: 18px !important;
  -webkit-text-stroke: 0.3px !important;
}
.span.flatpickr-weekday {
  color: white !important;
}
.flatpickr-wrapper {
  width: 100%;
  background-color: #fff;
  font-size: 18px;
  color: #262626;
  font-family: Quicksand-Regular;
  border-radius: 8px;
  height: 41px;
  border-radius: 10px;
  cursor: pointer;
}
.date_picker_wrapper .flatpickr-wrapper {
  border:1px solid #ced4da;
  margin-top: 5px;
}
.flatpickr-calendar,.flatpickr-days{
  width: 275px !important;
}
.dayContainer{
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important;
}


.text-stroke-4 {
  -webkit-text-stroke: 0.4px;
}

.text-stroke {
  -webkit-text-stroke: 0.3px;
}

.text-stroke-2 {
  -webkit-text-stroke: 0.2px;
}

.text-stroke-1 {
  -webkit-text-stroke: 0.1px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Styles for the input range */
input[type="range"] {
  /* Remove default styles */
  -webkit-appearance: none;
  -moz-apperance: none;
  appearance: none;
  /* Width and height of the slider */
  width: 300px;
  height: 19px;
  /* Custom track */
  background: transparent;
  /* Remove default outline */
  outline: none;
}

/* Style the track */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  /* Height of the line */
  background: #756b55;
  /* Color of the line */
  border-radius: 3px;
  /* Rounded corners */
}

/* Style the thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  /* Width of the thumb */
  height: 15px;
  /* Height of the thumb */
  background: #dddddd;
  /* Color of the thumb */
  border-radius: 50%;
  /* Shape of the thumb */
  cursor: pointer;
  /* Cursor on hover */
  transform: translateY(-50%);
}

/* Firefox */
input[type="range"]::-moz-range-thumb {
  width: 15px;
  /* Width of the thumb */
  height: 15px;
  /* Height of the thumb */
  background: transparent;
  /* Color of the thumb */
  border-radius: 50%;
  /* Shape of the thumb */
  cursor: pointer;
  /* Cursor on hover */
  transform: translateY(-50%);
}

/* IE */
input[type="range"]::-ms-thumb {
  width: 15px;
  /* Width of the thumb */
  height: 15px;
  /* Height of the thumb */
  background: transparent;
  /* Color of the thumb */
  border-radius: 50%;
  /* Shape of the thumb */
  cursor: pointer;
  /* Cursor on hover */
  transform: translateY(-50%);
}

.style-scroll-6-px::-webkit-scrollbar {
  width: 10px;
  height: 2px;
  background-color: #ebebeb;
  border-radius: 8px;
}

.style-scroll-6-px::-webkit-scrollbar-thumb {
  background-color: #aa7f2a;
  border-radius: 8px;
}

/* Hide the default calendar icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  z-index: 9;
  height: 26px;
  width: 26px;
  position: absolute;
  right: 10px;
  /* Adjust based on your icon size and input field padding */
  top: 50%;
  transform: translateY(-50%);
}


/* Container for the custom date input */
.custom-date-container {
  position: relative;
  display: inline-block;
}
.focus_dropDown {
    outline: -webkit-focus-ring-color auto 1px;
}
/* Style the input field */
.custom-date-container input[type="date"] {
  padding-right: 30px;
  /* Adjust padding to make space for the icon */
}
/* Custom calendar icon */
.flatpickr-wrapper::after {
  content: url('../images/png/dob_icon.png');
  /* Path to your custom image */
  position: absolute;
  right: 10px;
  /* Adjust based on your icon size and input field padding */
  top: 58%;
  transform: translateY(-50%);
  cursor: pointer;
  /* Change cursor to pointer to indicate it's clickable */
}
.date_picker_wrapper .flatpickr-wrapper::after {
  top: 58% !important;
}
.flatpickr-weekday {
  color: white !important;
}
.Toastify__toast-container {
  width: fit-content !important;
}
.border-radius {
  border-radius: 0 8px 8px 0;
}
.dropDown_arrow {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
  margin-left: 8px;
}
.focus_dropdown:focus-visible {
  outline: none;
}
@media (max-width:480px) {
  .Toastify__toast-container {
    width: 100vw !important;
  }
}
@media (max-width:767px) {
  .dropdown_value {
    width: calc(100% - 20px);
  }
}
@media (max-width:576px) {
  input[type="range"] {
    width: auto;
  }
}